<template>
  <div :class="{ 'homeHeaderDiv': $route.name === 'home', 'headerDiv': $route.name !== 'home' }">
    <nav class="custom-navbar navbar navbar navbar-expand-md navbar-dark bg-white" arial-label="Furni navigation bar">
      <div class="imgLogoDiv">
        <img alt="F4C logo" :class="{ 'headerHomeLogo': $route.name === 'home', 'headerLogo': $route.name !== 'home' }"
          src="@/assets/logoF4C.png" width="300" height="125" />
      </div>
      <div class="collapse navbar-collapse w-100">
        <ul class="custom-navbar-nav navbar-nav ms-auto mb-2 mb-md-0 ml-auto">
          <li :class="{ active: $route.name === 'home' }" class="nav-item">
            <router-link class="nav-link" :to="{ name: 'home' }">{{ $t("Home") }}</router-link>
          </li>
          <li v-if="isAuthenticated" :class="{ active: $route.name === 'questionnaire' }" class="nav-item">
            <router-link class="nav-link" to="#" @click.prevent="goToTermsPage">{{ $t("Questionnaire") }}</router-link>
          </li>

          <li v-if="!isAuthenticated" :class="{ active: $route.name === 'login' || $route.name === 'signup' }"
            class="nav-item">
            <router-link class="nav-link" :to="{ name: 'login' }">{{ $t("Login") }}</router-link>
          </li>
          <li v-if="isAuthenticated" class="nav-item dropdown">
            <a href="javascript:;" class="nav-link dropdown-toggle" id="dropdownUser" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false"><i class="fas fa-user"></i></a>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownUser">
              <router-link :to="{ name: 'repository' }" class="dropdown-item">{{ $t("Repository") }}</router-link>
              <a href="javascript:;" class="dropdown-item" @click="logout">{{ $t("Log out") }}</a>
            </div>
          </li>
          <li class="nav-item">
            <a href="javascript:;" class="nav-link" @click="openSelectLanguage"
              style="display: flex; align-items: center;">
              <link rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,400,0,0" />
              <span class="material-symbols-outlined">language</span><span style="margin-left: 5px">{{
                renderLanguage(lang, languages) }}</span>
            </a>

          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script lang="ts">
import { AppEvents } from "@/control/app-events";
import { defineComponent } from "vue";
import { AppPreferences } from "@/control/app-preferences";
import { AuthController } from "@/control/auth";


export default defineComponent({
  components: {},
  name: "TopBar",
  emits: ['toggle-menu'],
  data: function () {
    return {
      languages: AppPreferences.AvailableLanguages.slice(),
      lang: AppPreferences.Language,
      isAuthenticated: AuthController.isAuthenticated(),
    };
  },
  methods: {
    openUserSettings: function () {
      AppEvents.Emit("account-settings-open");
    },

    logout: function () {
      AppEvents.Emit("logout-open");

    },

    renderLanguage: function (lang, languages) {
      for (let l of languages) {
        if (l.id === lang) {
          return l.id.toUpperCase();
        }
      }
      return "-";
    },

    openSelectLanguage: function () {
      AppEvents.Emit("select-language-open");
    },

    updateLang: function (l: string) {
      this.lang = AppPreferences.Language;
    },

    onAuthChanged: function () {
      this.isAuthenticated = AuthController.isAuthenticated();
    },

    goToTermsPage() {

      this.$router.push('/').then(() => {
        AppEvents.Emit('go-to-terms');
      });

    },
  },
  mounted: function () {
    this.$options.updateLangH = this.updateLang.bind(this);
    AppEvents.AddEventListener("set-locale", this.$options.updateLangH);

    this.$options.onAuthChangedH = this.onAuthChanged.bind(this);
    AppEvents.AddEventListener("auth-status-changed", this.$options.onAuthChangedH);

  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("set-locale", this.$options.updateLangH);

    AppEvents.RemoveEventListener("auth-status-changed", this.$options.onAuthChangedH);
  },
});
</script>

<style>
.homeHeaderDiv {
  width: 100%;
  height: 175px;
  align-items: center;
  display: flex;
}

.headerDiv {
  width: 100%;
  height: 75px;
  align-items: center;
  display: flex;
}

.imgLogoDiv {
  margin-left: 20px;
}

.headerHomeLogo {
  width: 300px;
  height: 125px;
}

.headerLogo {
  width: 120px;
  height: 50px;
}

@media (max-width: 767px) {
  .custom-navbar {
    display: block;
  }

  .container {
    width: 100% !important;
    flex-wrap: nowrap;
    margin-top: 15px
  }

  .container-sm,
  .container {
    max-width: 100%;
  }

  .navbar-nav {
    flex-direction: row;
  }

  .nav-link {
    margin-right: 25px;
  }

  .collapse:not(.show) {
    display: block !important;
  }

  .headerHomeLogo {
    width: 250px !important;
    height: 100px !important;
  }
}
</style>
