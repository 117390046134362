<template>
  <div
    class="modal modal-container modal-container-corner no-transition"
    :class="{ hidden: !display }"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!display"
    @click="close"
    @keydown="escapeToClose"
  >
    <div class="modal-dialog modal-md" role="document" @click="stopPropagationEvent">
      <div class="modal-content">
        <div class="modal-header">
          <div class="modal-title">{{ $t("Select your language") }}</div>
          <button
            type="button"
            class="modal-close-btn"
            :title="$t('Close')"
            @click="goBack"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>

        <div class="modal-body no-padding">
          <div class="settings-list">
            <div
              v-for="l in languages"
              :key="l.id"
              class="settings-list-item"
              tabindex="0"
              @keydown="clickOnEnter"
              @click="changeLocale(l.id)"
            >
              <div
                class="settings-list-item-icon"
                :class="{ 'check-invisible': l.id !== lang }"
              >
                <i class="fas fa-check"></i>
              </div>
              <div class="settings-list-item-caption">{{ l.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { AppEvents } from "@/control/app-events";
import { AppPreferences } from "@/control/app-preferences";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "../../utils/vmodel";
import { FocusTrap } from "../../utils/focus-trap";

export default defineComponent({
  name: "ChangeLanguageModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  data: function () {
    return {
      languages: AppPreferences.AvailableLanguages.slice(),
      lang: AppPreferences.Language,
    };
  },
  methods: {
    open: function () {
      this.displayStatus = true;
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    clickOnEnter: function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        event.stopPropagation();
        event.target.click();
      }
    },

    changeLocale: function (l: string) {
      this.lang = l;
      //Add language to localstorage for refresh
      localStorage.setItem('userLanguage', l);

      AppPreferences.SetLanguage(l);
      AppEvents.Emit("set-locale", l);
      this.close();
    },

    goBack: function () {
      this.close();
      AppEvents.Emit("account-settings-open");
    },
  },
  mounted: function () {
    //Get language from appPreferences (not persist when refreshing)
    //this.lang = AppPreferences.Language;
    //AppPreferences.SetLanguage(this.lang);
    //AppEvents.Emit("set-locale", this.lang);

    //Get language from localstorage (persist when refreshing)
    const savedLanguage = localStorage.getItem('userLanguage') || AppPreferences.Language;
    this.lang = savedLanguage;

    AppPreferences.SetLanguage(this.lang);
    AppEvents.Emit("set-locale", this.lang);
    
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("select-language-open", this.$options.openH);

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("select-language-open", this.$options.openH);
    this.$options.focusTrap.destroy();
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$options.focusTrap.activate();
        nextTick(() => {
          this.$el.focus();
        });
      } else {
        this.$options.focusTrap.deactivate();
      }
    },
  },
});
</script>

<style>
.settings-list {
    display: flex;
    flex-direction: column;
}

.settings-list-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    cursor: pointer;
    padding: 0.5rem;
}

.settings-list-item:hover {
    background: var(--hover-color);
}

.settings-list-item-icon {
    padding: 0.5rem;
    font-size: 16px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.settings-list-item-caption {
    padding: 0.5rem;
    width: 100%;
    font-size: 14px;
}

.settings-list-item-arrow {
    padding: 0.25rem;
}

.check-invisible {
    visibility: hidden;
}
</style>
