// Vue entry point

"use strict";

import { VueReCaptcha } from 'vue-recaptcha-v3'
import { makeApplicationRouter } from './routes';
import { createApp } from 'vue'
import App from './App.vue'
import { AuthController } from './control/auth';
import { i18n } from './i18n'
import { surveyPlugin } from 'survey-vue3-ui'
import SweetAlert2 from 'vue-sweetalert2';

// Initialize stuff
AuthController.Initialize();


// Global event handlers

document.addEventListener("drop", function (e) {
    e.preventDefault();
    e.stopPropagation();
});

document.addEventListener("dragover", function (e) {
    e.preventDefault();
    e.stopPropagation();
});

// Setup App

createApp(App)
    .use(i18n) // Multi-language
    .use(makeApplicationRouter()) // Router
    .use(surveyPlugin)
    .use(VueReCaptcha, {
        siteKey: process.env.RECAPTCHA_SITE_ID,
        loaderOptions: {
          // Aquí puedes configurar las opciones adicionales si es necesario
        }
    }) // Recaptcha
    .use(SweetAlert2)
    .mount('#app');
