<template>
  <!--<div class="main-layout" :class="{
    'sidebar-hidden': !sidebarExpanded,
    'dark-theme': isDarkTheme,
    'light-theme': !isDarkTheme,
  }">-->

  <div class="main-layout light-theme">

    <a v-if="!loading" href="#" @click="skipMainContent" class="skip-main-content">
      <span>{{ $t("Skip to main content") }}</span>
    </a>
    
    <!--<div v-if="sidebarExpanded" class="side-bar-overlay" @click="closeSideBar"></div>-->
    <!--<MenuSideBar v-if="!loading" v-model:expanded="sidebarExpanded"></MenuSideBar>-->
    <TopBar @toggle-menu="toggleMenu"></TopBar>

    <!--<LoadingOverlay :display="loading"></LoadingOverlay>-->
    <!--<AuthLoadingOverlay :display="loadingAuth"></AuthLoadingOverlay>-->

    <router-view></router-view>

    <LogoutModal v-model:display="displayModalLogout"></LogoutModal>
    <ChangeLanguageModal v-model:display="displayModalLanguage"></ChangeLanguageModal>

    <SnackBar></SnackBar>

    <FooterLayout></FooterLayout>

  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import TopBar from "@/components/layout/TopBar.vue";
import MenuSideBar from "@/components/layout/MenuSideBar.vue";
//import LoadingOverlay from "@/components/layout/LoadingOverlay.vue";
//import AuthLoadingOverlay from "@/components/layout/AuthLoadingOverlay.vue";
import SnackBar from "@/components/layout/SnackBar.vue";
import FooterLayout from "@/components/layout/FooterLayout.vue";
import LogoutModal from "@/components/modals/LogoutModal.vue";
import ChangeLanguageModal from "@/components/modals/ChangeLanguageModal.vue";

import { AuthController } from "@/control/auth";
import { AppEvents } from "@/control/app-events";
import { AppPreferences } from "@/control/app-preferences";

const unauthorizedAllowedRoutes = [
  "home",
  "login",
  "signup",
  "signup-success",
  "tfa-login",
  "forgot-password",
  "reset-password",
  "verify-email",
  "invalid-certificate",
  "valid-certificate-callback",
  "questionnaire",
];

export default defineComponent({
  components: {
    TopBar,
    //MenuSideBar,
    //LoadingOverlay,
    //AuthLoadingOverlay,
    SnackBar,
    LogoutModal,
    ChangeLanguageModal,
    FooterLayout,
  },
  name: "MainLayout",
  data: function () {
    return {
      sidebarExpanded: true,
      isDarkTheme: false,
      loading: !AuthController.isAuthenticated(),
      loadingAuth: AuthController.Loading,

      displayModalLogout: false,
      displayModalLanguage: false,
      displayModalTheme: false,
      displayModalCurrency: false,
      displayModalChangeUsername: false,
      displayModalChangeEmail: false,
      displayModalChangePassword: false,
      displayModalDeleteAccount: false,
      displayModalDevices: false,
      displayModalTwoFactorAuthEnable: false,
      displayModalTwoFactorAuthDisable: false,

      displayModalProfile: false,

      displayModalMessage: false,
      messageModalTitle: "",
      messageModalMessage: "",
    };
  },
  methods: {
    onAuthStatusUpdate: function () {
      this.loading = !AuthController.isAuthenticated();

      this.displayModalLogout = false;
      this.displayModalAccountSettings = false;
      this.displayModalChangeUsername = false;
      this.displayModalChangeEmail = false;
      this.displayModalChangePassword = false;
      this.displayModalDeleteAccount = false;
      this.displayModalDevices = false;
      this.displayModalTwoFactorAuthEnable = false;
      this.displayModalTwoFactorAuthDisable = false;

      if (AuthController.Loading) {
        return;
      }
      console.log("main-layout: this.route: ", this.$route.name );
      if (
        this.loading &&
        !unauthorizedAllowedRoutes.includes(this.$route ? this.$route.name : "")
      ) {
        AuthController.PageToGo = this.$route ? (this.$route.name || "home") : "home";
        AuthController.PageToGoParams = this.$route ? (this.$route.params || {}) : {};
        if (AuthController.isAskingForTwoFactor()) {
          this.$router.push({ name: "tfa-login" });
        } else {
          this.$router.push({ name: "login" });
        }
      }
    },

    onAuthLoadingChanged: function () {
      this.loadingAuth = AuthController.Loading;
    },

    onThemeChanged: function () {
      this.isDarkTheme = AppPreferences.Theme === "dark";
    },

    toggleMenu: function () {
      this.sidebarExpanded = !this.sidebarExpanded;
    },

    closeSideBar: function () {
      this.sidebarExpanded = false;
    },

    skipMainContent: function (event) {
      if (event) {
        event.preventDefault();
      }
      const content: HTMLElement = document.querySelector(".page-content");
      if (content) {
        content.focus();
      }
    },

    showMessage: function (title: string, msg: string) {
      this.messageModalTitle = title;
      this.messageModalMessage = msg;
      this.displayModalMessage = true;
    },
  },
  mounted: function () {
    if (document.documentElement.clientWidth < 1000) {
      this.sidebarExpanded = false;
    } else {
      this.sidebarExpanded = true;
    }

    this.$options.onAuthStatusUpdateH = this.onAuthStatusUpdate.bind(this);
    AppEvents.AddEventListener(
      "auth-status-changed",
      this.$options.onAuthStatusUpdateH
    );

    this.$options.onAuthLoadingChangedH = this.onAuthLoadingChanged.bind(this);
    AppEvents.AddEventListener(
      "auth-status-loading",
      this.$options.onAuthLoadingChangedH
    );

    this.$options.onThemeChangedH = this.onThemeChanged.bind(this);
    AppEvents.AddEventListener("theme-changed", this.$options.onThemeChangedH);
    this.onThemeChanged();

    this.$options.showMessageH = this.showMessage.bind(this);
    AppEvents.AddEventListener("msg-modal", this.$options.showMessageH);

    this.onAuthStatusUpdate();
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener(
      "auth-status-changed",
      this.$options.onAuthStatusUpdateH
    );
    AppEvents.RemoveEventListener(
      "auth-status-loading",
      this.$options.onAuthLoadingChangedH
    );
    AppEvents.RemoveEventListener(
      "theme-changed",
      this.$options.onThemeChangedH
    );

    AppEvents.RemoveEventListener("msg-modal", this.$options.showMessageH);
  },
  watch: {
    $route: function () {
      this.onAuthStatusUpdate();
      if (document.documentElement.clientWidth < 1000) {
        this.sidebarExpanded = false;
      } else {
        this.sidebarExpanded = true;
      }
    },
  },
});
</script>

<style></style>
