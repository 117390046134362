<template>
<div class="modal modal-container modal-container-logout" 
    :class="{ hidden: !display }"
    tabindex="-1"
    role="dialog"
    :aria-hidden="!display"
    @click="close"
    @keydown="escapeToClose">
  <div class="modal-dialog" role="document" @click="stopPropagationEvent">
    <div class="modal-content">
      <div class="modal-header">
        <div class="modal-title">{{ $t("Log out") }}</div>
        <button type="button" class="modal-close-btn" :title="$t('Close')" @click="close">
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div class="modal-body">
        <p>{{ $t("Do you want to log out of the application?") }}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" @click="logout">
          <i class="fas fa-sign-out-alt"></i> {{ $t("Log out") }}
        </button>
      </div>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { AppEvents } from "@/control/app-events";
import { AuthController } from "@/control/auth";
import { defineComponent, nextTick } from "vue";
import { useVModel } from "../../utils/vmodel";
import { FocusTrap } from "../../utils/focus-trap";

export default defineComponent({
  name: "LogoutModal",
  emits: ["update:display"],
  props: {
    display: Boolean,
  },
  setup(props) {
    return {
      displayStatus: useVModel(props, "display"),
    };
  },
  methods: {
    open: function () {
      this.displayStatus = true;
    },

    close: function () {
      this.displayStatus = false;
    },

    escapeToClose: function (event) {
      if (event.key === "Escape") {
        this.close();
      }
    },

    stopPropagationEvent: function (e) {
      e.stopPropagation();
    },

    logout: function () {
      AuthController.Logout();
      this.close();
    },
  },
  mounted: function () {
    this.$options.openH = this.open.bind(this);
    AppEvents.AddEventListener("logout-open", this.$options.openH);

    this.$options.focusTrap = new FocusTrap(this.$el, this.close.bind(this));
  },
  beforeUnmount: function () {
    AppEvents.RemoveEventListener("logout-open", this.$options.openH);
    this.$options.focusTrap.destroy();
  },
  watch: {
    display: function () {
      if (this.display) {
        this.$options.focusTrap.activate();
        nextTick(() => {
          this.$el.focus();
        });
      } else {
        this.$options.focusTrap.deactivate();
      }
    },
  },
});
</script>

<style>
</style>
