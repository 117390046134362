<template>
  <MainLayout></MainLayout>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import MainLayout from "@/components/layout/MainLayout.vue";

@Options({
  components: {
    MainLayout,
  },
  data: function () {
    return {};
  },
  methods: {},
  mounted: function () {},
  beforeUnmount: function () {},
})
export default class App extends Vue {}
</script>

<style>

/* Common style */

@import "@/style/main.css";

@import "@/style/theme.css";

@import "@/style/forms.css";

@import "@/style/modals.css";


/* Layout */

@import "@/style/layout/main-layout.css";

@import "@/style/layout/loader.css";

@import "@/style/layout/top-bar.css";

@import "@/style/layout/page.css";

@import "@/style/layout/side-bar.css";

@import "@/style/layout/snack-bar.css";

</style>
