import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "main-layout light-theme" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_LogoutModal = _resolveComponent("LogoutModal")!
  const _component_ChangeLanguageModal = _resolveComponent("ChangeLanguageModal")!
  const _component_SnackBar = _resolveComponent("SnackBar")!
  const _component_FooterLayout = _resolveComponent("FooterLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.loading)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: "#",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.skipMainContent && _ctx.skipMainContent(...args))),
          class: "skip-main-content"
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.$t("Skip to main content")), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_TopBar, { onToggleMenu: _ctx.toggleMenu }, null, 8, ["onToggleMenu"]),
    _createVNode(_component_router_view),
    _createVNode(_component_LogoutModal, {
      display: _ctx.displayModalLogout,
      "onUpdate:display": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.displayModalLogout) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_ChangeLanguageModal, {
      display: _ctx.displayModalLanguage,
      "onUpdate:display": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.displayModalLanguage) = $event))
    }, null, 8, ["display"]),
    _createVNode(_component_SnackBar),
    _createVNode(_component_FooterLayout)
  ]))
}