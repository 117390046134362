// Routes

"use strict";

import { createRouter, createWebHashHistory, Router } from "vue-router";

// Router
// https://router.vuejs.org/guide/#javascript

const routes = [
    {
        name: "home",
        path: '/',
        component: () => import("@/components/routes/HomePage.vue"),
    },

    {
        path: '/questionnaire',
        name: 'questionnaire',
        component: () => import('@/components/routes/QuestionnairePage.vue')
    },

    {
        path: '/repository',
        name: 'repository',
        component: () => import('@/components/routes/RepositoryPage.vue')
    },

    {
        name: "login",
        path: '/login',
        component: () => import("@/components/routes/LoginPage.vue"),
    },

    {
        name: "example-page",
        path: '/example',
        component: () => import("@/components/routes/HomePage.vue"),
    },

    {
        name: "tfa-login",
        path: '/login/tfa',
        component: () => import("@/components/routes/TwoFactorLoginPage.vue"),
    },

    {
        name: "signup",
        path: '/signup',
        component: () => import("@/components/routes/SignupPage.vue"),
    },
    {
        name: "signup-success",
        path: '/signup/success',
        component: () => import("@/components/routes/SignupSuccessPage.vue"),
    },

    {
        name: "forgot-password",
        path: '/password/forgot',
        component: () => import("@/components/routes/ForgotPasswordPage.vue"),
    },
    {
        name: "reset-password",
        path: '/password/reset/:uid/:token',
        component: () => import("@/components/routes/ResetPasswordPage.vue"),
    },

    {
        name: "verify-email",
        path: '/email/verify/:uid/:token',
        component: () => import("@/components/routes/EmailVerifyPage.vue"),
    },

    {
        name: "invalid-certificate",
        path: '/error/invalid_cert',
        component: () => import("@/components/routes/InvalidCertificatePage.vue"),
    },

    {
        name: "valid-certificate-callback",
        path: '/cert_callback',
        component: () => import("@/components/routes/ValidCertificateCallback.vue"),
    },
];

export function makeApplicationRouter(): Router {
    const router = createRouter({
        // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
        history: createWebHashHistory(),
        routes, // short for `routes: routes`
    })

    return router;
}
