<template>
  <div :class="{ 'footerFixed': $route.name !== 'questionnaire', 'footer': $route.name === 'questionnaire' }">
    <img alt="Prima logo" class="logo" src="@/assets/logoPrima.png" width="150" height="50" />
    <img alt="UE logo" class="logo" src="@/assets/logoUE.png" width="70" height="70" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({

  name: "FooterLayout",
  data: function () {
    return {
    };
  },
  methods: {


  },
  mounted: function () {
  },

});
</script>

<style>
.footer {
  margin-top: auto;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  height: 100px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  bottom: 0;
  left: 0;
}

.footerFixed {
  margin-top: auto;
  display: flex;
  position: absolute;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  height: 100px;
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  bottom: 0;
  left: 0;
}
</style>